.notificationbox{

}

.notificationbox ul{
    margin: 0;
    padding: 0;
}

.notificationbox li{
    list-style: none;
}

.notificationbox .top{
    display: flex;
    align-items: center;
    padding: 0.6em;
    transition: background-color 100ms ease-out;
}

.notificationbox .name {
    font-weight: 600;
}

.notificationbox .role {
    font-weight: normal;
    margin-top:0.3em;
}


.notificationbox .notificationbox-user-info{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 1em;
}

.top:not(.no-hover):hover {
    border-radius: 10px;
    background-color: rgba(171, 170, 168, 0.15);
    cursor: pointer;
}

.notification-modal{
    background-color: #c7c7c7;
    position: fixed;
    top:80px;
    right: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.notification-modal{
    display: none;
    box-shadow: -20px 20px 50px 0px rgba(0,0,0,0.1),20px 20px 50px 0px rgba(0,0,0,0.1);
    background-color: white;
    border-radius: 10px;
    padding: 0 1em 3em 1em;
    transition: height 4s ease-in-out;
    z-index: 2000;
}

.notification-modal.show {
    display: flex;
    height: auto; /* Auto height when shown */
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.02); /* Semi-transparent black background */
    backdrop-filter: blur(5px); /* Apply blur effect */
    z-index: 999; /* Ensure backdrop is above other content */
    pointer-events: none; /* Allow clicking through the backdrop */
}

.notification-modal .notifications ul {

}

.notification-modal .notifications ul li{
    margin: 1em;
}

.notification-modal .clear-button-container{
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}

.notification-modal .top :hover{
    background-color: inherit;
}
