.sidenav {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 240px;
  position: fixed;
  overflow-y: auto;
  transform: translateX(-245px);
  transition: all 0.6s ease-in-out;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  z-index: 2; /* Needs to sit above the hamburger menu icon */
  background-color: white;
  /*padding: 0.4em;*/

  .logo{
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    color: black;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: start;
    margin: 2em 0.4em 0 0.4em;
    font-family: "Open Sans", sans-serif;

    img{
      height: 53px;
    }
  }

  a{
    text-decoration: none;
  }

  .sidenav__list {
    padding: 0;
    margin: 2em 0.1em 0 0.4em;
    list-style-type: none;

    .sidenav__list-item {
      padding: 0.5em 2em 0.5em 1em;
      border-radius: 8px;
      align-items: center;
      font-size: 16px;
      color: #3B3B4F;
      margin-bottom: 1em;
      display: flex;
      justify-content: start; /* Center horizontally */
      transition: background-color 0.3s ease; /* Transition for background color */

      &.link-active {
        color: #4A85F6; /* Text color for active link */
        border-right: solid 4px #4A85F6;
        border-radius: 0;
      }

    }

    .sidenav__list-item:hover {
      background-color: #7BBAFE;
      cursor: pointer;
    }


    .sidenav__icon {
      margin-right: 1em;
    }

  }

}


@media only screen and (min-width: 46.875em) {


  .sidenav {
    position: relative;
    transform: translateX(0);
  }

  .sidenav__close-icon {
    visibility: hidden;
  }

}


