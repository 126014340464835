header {

}


.top-bar{
    text-align: center ;
    display: flex;
    background-color: #4169E1;
    padding: 0.5em 1em;
}

.top-bar-box {
    flex: 1; /* Each box takes an equal amount of space */
    display: flex;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
}

.top-bar-box.top-bar_center{
    /*display: flex;*/
    /*justify-content: center; !* Horizontally center the content *!*/
    /*align-items: center; !* Vertically center the content *!*/
}

.top-bar-box.top-bar_left{
    display: flex;
    justify-content: flex-start;
}

.top-bar_left a{
    margin-left: 0.4em;
}

.top-bar-box.top-bar_right{
    justify-content: flex-end;
}

.top-bar-box.top-bar_right > div {
    margin-left: 1.5em;
}


/* styles.css */

/* Style the horizontal navigation bar */
.horizontal-nav {
    padding: 0 0; /* Add some padding for spacing */
    display: flex;
    justify-content: center;
    color: #FFF;
    font-family: Inter, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

/* Style the menu items */
.horizontal-nav ul {
    flex: 1;
    list-style: none; /* Remove bullet points from the list */
    padding: 0;
    display: flex;
    justify-content: space-between;
    /*margin-left: 2em;*/
    /*margin-right: 2em;*/
    margin: 0.8em 2em;
    align-items: center;
}

.store-header .horizontal-nav ul{
    justify-content: space-around;
    gap: 2em;
}

.horizontal-nav li {
    display: inline; /* Display menu items horizontally */
    /*margin-right: 1em; !* Add spacing between menu items *!*/
    padding: 0;
}

/* Style the menu item links */
.menu-item {
    text-decoration: none; /* Remove underlines from links */
    color: white; /* Text color for menu items */
    font-weight: bold; /* Make menu item text bold */

}

.nous-contacter, .Login{
    color: white; /* Set the text color to white */
    text-decoration: none; /* Remove any text decoration (underline) */
}

.nav-bar-lg{
    background-color: #4169E1; /* Background color of the navigation bar */
    align-items: center;
    /*height: 98px;*/
}

.menu-item.join{
    margin-left: 0.4em;
}

.logo{
    /*margin-right: 4em;*/
}




/* Mobile-first styles (applies to all screen sizes) */

/* Styles for screens up to 768px (e.g., smartphones in portrait mode) */
@media (max-width: 768px) {
    /* Your mobile-specific styles go here */
    .nav-bar-lg , .nous-contacter{
        display: none;
    }

}

/* Styles for screens between 769px and 1024px (e.g., tablets in portrait mode) */
@media (min-width: 769px) and (max-width: 1024px) {
    .nav-bar-lg , .nous-contacter{
        display: none;
    }



}

/* Styles for screens wider than 1024px (e.g., desktop monitors) */
@media (min-width: 1025px) {
    /* Your desktop-specific styles go here */
    .top-bar{
        display: none;
    }

    .cart-menu > .burger-icon {
        display: none;
    }

    .top_bar{
        display: none;
    }


}


@media (min-width: 1125px) {

    .logo{
        margin-right: 4em;
    }

}
