footer {
    margin-top: 2em;
    background-color: #E8E8EA;
    color: #fff;
    text-align: center;
    padding: 4em 2em 1em 2em;
}

footer h4{
    font-size: 18px;
    font-weight: 600;
    color: #181A2A;
    margin: 0;
}

footer p {
    text-align: left;
    color: #696A75;
    font-size: 16px;
}

footer .content{
    display: flex;
    justify-content: space-between;
    margin-bottom: 2em;
}




.about{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.about p{
    text-align: left;
    width: 15em;
}

.page-links , .blog-categories{
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
}



.page-links > ul,
.blog-categories > ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
}

.page-links  li,
.blog-categories  li{
    margin-bottom: 0.8em;
}

.page-links .footer-link-item , .blog-categories .footer-link-item{
    margin: 1em 0;
}

.newsletter{

}

.newsletter-form-container{
    background-color: white;
    border-radius: 12px;
    padding: 1em 4em;
}

.newsletter-form{
    display: flex;
    flex-direction: column;
    margin-bottom: 0.4em;
}

.newsletter .input-with-icon{
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px solid #DCDDDF;
    padding: 0 0.8em;
    background-color: white;
    border-radius: 6px;
    margin-bottom: 2.5em;
}


.newsletter .input-with-icon input {
    flex: 1;
    border: none;
    outline: none;
    height: 3em;
}






footer .legal{
    display: flex;
    justify-content: space-between;
    margin-bottom: 2em;
    border-top: 1px solid #DCDDDF;
    padding-top: 1em;

}

footer .legal .info{
    display: flex;
}

footer .legal .info ul{
    display: flex;
}

li{
    list-style: none;
}

.footer-link-item {
    text-align: left;
    font-size: 16px; /* Adjust font size as needed */
    /*padding: 10px 20px; !* Add spacing between items *!*/
    transition: background-color 0.2s; /* Smooth background color transition */
    cursor: pointer;
    font-weight:normal;
    margin: 0 0.5em;
    text-decoration: none;
    color: #3B3C4A;
}

.copy-right{
    color: #3B3C4A;
    font-size: 16px;
    font-weight: normal;
    text-align: center;
    margin-bottom: 2em;
}




@media only screen and (max-width: 1000px) {
    footer .content{
        /*flex-direction: column;*/
        flex-wrap: wrap;
        align-content: center;
        gap: 2em;
    }

    footer .content .page-links {
        width: 40%;
        margin-left: 10px;
    }
    footer .content .blog-categories{
        width: 40%;
    }

    footer .content .newsletter{
        width: 100%;
        display: flex;
        justify-content: center;
    }

    footer .content .newsletter .newsletter-form-container{
        width: 70%;
    }
}

@media only screen and (max-width: 480px) {
    footer .content{
        flex-direction: row;
    }
}
