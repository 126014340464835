.header{
    background: #F8F9FA;
    z-index: 100;
    /*box-shadow: 0 17px 8px -7px rgba(0,0,0,0.1);*/
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;

    .right-container{
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;

        > div{
            margin-left: 1em;
        }

        .notification{
            border-right: solid 1px #C2C2C2;
            padding-right: 1em;

            svg{
                width: 24px;
                height: 24px;
            }
        }
    }

    .left-container{
        div:first-child{
            color: var(--Neutral-100, #0A0A0A);
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%; /* 30px */
            letter-spacing: 0.15px;
        }
        div:last-child{
            color: var(--Neutral-70, #757575);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
        }
    }

}



