@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.cdnfonts.com/css/avenir');
:root {
  --main: #0050A5;
  --main-admin: #4A85F6;
  --default-text:#3B3B4F;
  --gray-text:#C3BFBF;
  --white: #ffffff;
  --color-white: #ffffff;
  --color-purple-800:#0C4284;
  --color-gray-200 : #DDD;
  --loading-grey: #ededed;

}


body {
  margin: 0;
  overflow-x: hidden;
  background-color: #F5F5F5;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*{
  font-family: 'Avenir', sans-serif;
}

#root{
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2{

}

.text_title-lg{
  font-size: 40px;
}

.text_title-md{
  font-size: 34px;
}

.test_title-sm{

}

.no-decoration{
  text-decoration: none;
}

.no-link-style{

}

.no-style {
  text-decoration: none; /* Remove underline */
  color: inherit;        /* Inherit the color from the parent element */
}

.no-style:hover, 
.no-style:focus, 
.no-style:active {
  text-decoration: none; /* Ensure no underline on hover or focus */
  color: inherit;        /* Keep color unchanged on hover or focus */
}


.page{
  padding: 2em;
}



.button-loading {
  background-color: #f2f2f2; /* or any other gray color */
}



@media (max-width: 1024px) {
  .hide-mobile-tablet {
    display: none !important;
  }
}



/* Default placeholder style */
input::placeholder,
textarea::placeholder {
  color: gray !important;
}

/* Optional: You can further customize font size, weight, etc. */
input::placeholder,
textarea::placeholder {
  font-size: 16px !important;
  font-weight: 800 !important;
}
