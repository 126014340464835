/*body {*/
/*    margin: 0;*/
/*    padding: 0;*/
/*    font-family: "Open Sans", Helvetica, sans-serif;*/
/*    box-sizing: border-box;*/
/*}*/

/* Assign grid instructions to our parent grid container, mobile-first (hide the sidenav) */
.grid-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 80px 1fr 10px;
    grid-template-areas:
    "header"
    "main"
    "footer";
    height: 100vh;
    width: 100vw;
    position: fixed;
    transition: grid-template-columns 0.3s ease; /* Add transition for grid changes */
}

.menu-icon {
    position: fixed; /* Needs to stay visible for all mobile scrolling */
    display: flex;
    top: 5px;
    left: 10px;
    align-items: center;
    justify-content: center;
    background-color: #dadae3;
    border-radius: 50%;
    z-index: 1;
    cursor: pointer;
    padding: 12px;
}

/* Give every child element its grid name */
.header {
    grid-area: header;
}

/* Make room for the menu icon on mobile */
.header__search {
    margin-left: 42px;
}

.sidenav {
    grid-area: sidenav;
}

.sidenav.active {
    transform: translateX(0);
}

.sidenav__close-icon {
    position: absolute;
    visibility: visible;
    top: 8px;
    right: 12px;
    cursor: pointer;
    font-size: 20px;
    color: #ddd;
}

.main {
    grid-area: main;
    overflow-y: auto;
    background: #F8F9FA;
    color: #2C2C2C;
}

.main-header {
    display: flex;
    justify-content: space-between;
    margin: 20px;
    padding: 20px;
    height: 150px;
    background-color: #e3e4e6;
    color: slategray;
}

.main-overview {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
    grid-auto-rows: 94px;
    grid-gap: 20px;
    margin: 20px;
}


.stats-card-container .stats-card_list {
    margin: 0;
    padding: 0;
    display: flex;
    gap: 2em;
}
.stats-card-container .stats-card_list .stats-card__item {
    display: flex;
    min-width: 200px;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 5px;
    background: var(--color-white, #FFF);
}
.stats-card-container .stats-card_list .stats-card__item h5 {
    color: var(--color-gray-900, #2C2C2C);
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
}
.stats-card-container .stats-card_list .stats-card__item .stats-card__highlight {
    color: var(--color-gray-600, #8E8E8E);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}




.footer {
    grid-area: footer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #648ca6;
}

/* Non-mobile styles, 750px breakpoint */
@media only screen and (min-width: 46.875em) {
    /* Show the sidenav */
    .grid-container {
        grid-template-columns: 240px 1fr;
        grid-template-areas:
      "sidenav header"
      "sidenav main"
      "sidenav footer";
    }

    .header__search {
        margin-left: 0;
    }

    .sidenav {
        position: relative;
        transform: translateX(0);
    }

    .sidenav__close-icon {
        visibility: hidden;
    }

    .menu-icon{
        display: none;
    }
}

/* Medium screens breakpoint (1050px) */
@media only screen and (min-width: 65.625em) {

}
