.editor-full-page {
  height: 100vh;
  width: 100vw;
  background: #F8F9FA;
  display: grid;
  //grid-template-rows: auto 1fr;
  position: fixed;
  grid-template-columns: 1fr;
  grid-template-rows: 80px 1fr;
  grid-template-areas:
    "header"
    "content";

  .editor-full-page__header {
    grid-area: header;
  }

  .editor-full-page__main {
    grid-area: content;
    overflow-y: auto;
  }
}
