.aet-userbox{

}

.aet-userbox ul{
    margin: 0;
    padding: 0;
}

.aet-userbox li{
    list-style: none;
}

.aet-userbox .top{
    display: flex;
    align-items: center;
    padding: 0.6em;
    transition: background-color 100ms ease-out;

}

.aet-userbox .name {
    font-weight: 600;
}

.aet-userbox .role {
    font-weight: normal;
    margin-top:0.3em;
}


.aet-userbox .aet-userbox-user-info{
    display: none;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 1em;
    gap: 1px;

}



.top:not(.no-hover):hover {
    border-radius: 10px;
    background-color: rgba(171, 170, 168, 0.15);
    cursor: pointer;
}

.user-modal{
    background-color: #c7c7c7;
    position: fixed;
    top:0.4em;
    right: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.user-modal{
    display: none;
    box-shadow: -20px 20px 50px 0px rgba(0,0,0,0.1),20px 20px 50px 0px rgba(0,0,0,0.1);
    background-color: white;
    border-radius: 10px;
    padding: 0 1em 3em 1em;
    transition: height 4s ease-in-out;
    z-index: 2000;
}

.user-modal.show {
    display: flex;
    height: auto; /* Auto height when shown */
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.02); /* Semi-transparent black background */
    backdrop-filter: blur(5px); /* Apply blur effect */
    z-index: 999; /* Ensure backdrop is above other content */
    pointer-events: none; /* Allow clicking through the backdrop */
}

.aet-userbox .user-modal .options ul {
    color: black;
    display: flex;
    flex-direction: column;
    margin: 0;
    align-items: start;
}

.user-modal .options ul li{
    margin: 0.2em;
}

.user-modal .signout-button-container{
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}

.user-modal .top :hover{
    background-color: inherit;
}

.aet-userbox-container-guest_link{
    display: flex;
    align-items: center;
    gap: 1em;
    /*margin-right: -30px;*/
}

.aet-userbox-container-guest_link .join{
    display: none;
}

.aet-userbox-container-guest_link .join a{
    text-decoration: none;
}

.aet-userbox__arrow{
    display: none;
}

.go-to-admin{
    display: none;
}

/* Styles for screens up to 768px (e.g., smartphones in portrait mode) */
@media (max-width: 768px) {


}

/* Styles for screens between 769px and 1024px (e.g., tablets in portrait mode) */
@media (min-width: 769px) and (max-width: 1024px) {

}

/* Styles for screens wider than 1024px (e.g., desktop monitors) */
@media (min-width: 1025px) {
    .aet-userbox .aet-userbox-user-info{
        display: flex;
        color: white;
        gap: 2px;
    }

    .aet-userbox-container-guest_link .join{
        display: flex;
    }

    .aet-userbox__arrow{
        display: block;
    }

    .go-to-admin{
        display: block;
    }
}


@media (min-width: 1125px) {
    .aet-userbox .aet-userbox-user-info{
        display: flex;
        color: white;
        gap: 2px;
    }

    .aet-userbox__arrow{
        display: block;
    }

    .go-to-admin{
        display: block;
    }
}


